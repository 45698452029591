<template>
  <div class="card-form">
    <div class="card-list">
      <Card
        :fields="fields"
        :labels="formData"
        :is-card-number-masked="isCardNumberMasked"
        :is-card-flipped="isCardFlipped"
        random-backgrounds
      />
    </div>
    <div class="card-form__inner">
      <v-form ref="form" v-model="isValid">
        <div class="card-input">
          <label for="cardAccount" class="card-input__label"
            >Account Number</label
          >
          <v-text-field
            id="cardAccount"
            :value="formData.cardAccount"
            variant="outlined"
            density="comfortable"
            class="text-field"
            color="#1a3b5d"
            maxlength="8"
            autocomplete="off"
            rounded
            :rules="[required]"
            @input="accountNumber"
          />

          <label for="v-card-number" class="card-input__label"
            >Card number</label
          >
          <div style="position: relative">
            <v-text-field
              :id="fields.cardNumber"
              ref="textField"
              :value="formData.cardNumber"
              :maxlength="cardNumberMaxLength"
              variant="outlined"
              density="comfortable"
              class="text-field"
              color="#1a3b5d"
              :rules="[required, validCard]"
              data-card-field
              autocomplete="off"
              @input="changeNumber"
              @focus="focusCardNumber"
              @blur="blurCardNumber"
            />
            <span
              v-show="formData.cardNumber"
              class="card-input__eye"
              :class="{ '-active': !isCardNumberMasked }"
              title="Show/Hide card number"
              tabindex="-1"
              @click="toggleMask"
            ></span>
          </div>
          <div class="card-input">
            <label for="v-card-name" class="card-input__label"
              >Card holder name</label
            >
            <v-text-field
              :id="fields.cardName"
              :value="formData.cardName"
              variant="outlined"
              density="comfortable"
              class="text-field"
              color="#1a3b5d"
              maxlength="25"
              autocomplete="off"
              :rules="[required, onlyLatinLetters]"
              @input="changeName"
            />
          </div>
        </div>
        <div class="card-form__row">
          <div class="card-form__col mb-1">
            <label
              for="cardMonth"
              class="card-input__label"
              style="max-width: 200px"
              >Expiration date</label
            >
            <div class="d-flex date mr-3">
              <v-select
                v-model="formData.cardMonth"
                :items="month"
                label="Month"
                variant="outlined"
                density="default"
                menu-icon="mdi-chevron-down"
                class="select mr-3"
                style="max-width: 155px"
                :rules="[required]"
              />
              <v-select
                v-model="formData.cardYear"
                :items="years"
                label="Year"
                variant="outlined"
                density="default"
                style="max-width: 155px"
                menu-icon="mdi-chevron-down"
                class="select"
                :rules="[required]"
              />
            </div>
          </div>
          <div class="card-form__col -cvv">
            <div class="card-input">
              <label for="cardCvv" class="card-input__label">CVV</label>
              <v-text-field
                :id="fields.cardCvv"
                ref="cvv"
                :value="formData.cardCvv"
                variant="outlined"
                density="comfortable"
                class="text-field"
                color="#1a3b5d"
                maxlength="4"
                autocomplete="off"
                data-card-field
                style="min-width: 150px"
                :rules="[required]"
                @input="changeCvv"
                @focus="isCardFlipped = true"
                @blur="isCardFlipped = false"
              />
            </div>
          </div>
        </div>
        <label
          for="cardAmount"
          class="card-input__label"
          style="max-width: 230px"
          >Amount</label
        >
        <div class="d-flex">
          <v-text-field
            id="cardAmount"
            :value="formData.cardAmount"
            variant="outlined"
            density="comfortable"
            class="text-field amount"
            color="#1a3b5d"
            maxlength="3"
            autocomplete="off"
            style="width: 65%"
            :rules="[required, amount]"
            @input="cardAmount"
          />
          <v-select
            v-model="formData.cardCurrency"
            :items="currency"
            style="max-width: 55%"
            variant="outlined"
            density="default"
            menu-icon="mdi-chevron-down"
            class="select amount-select"
          />
        </div>
        <div class="d-flex align-start mb-2" style="position: relative">
          <v-checkbox
            v-model="formData.checkbox"
            :rules="[required]"
            color="#51b510"
            ><template #label>
              <span class="checkbox-text ml-10">
                I recognize that I have read, comprehended, and consent to be
                bound by the <a>terms outlined</a> in the
                <a>Client Agreement and Privacy Policy</a>, acknowledging that
                they may be subject to amendments periodically.
              </span>
            </template></v-checkbox
          >
        </div>
        <v-btn class="button" size="x-large" color="success" @click="submit"
          >submit</v-btn
        >
      </v-form>
      <div class="d-flex align-center justify-space-between image-wrapper mt-5">
        <v-img class="first" src="../assets/images/logo.png" />
        <v-img class="second" src="../assets/images/pci.png" />
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card";
import rules from "../utils/rules";
import { mapActions } from "vuex";

export default {
  name: "CardForm",
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  components: {
    Card,
  },
  data() {
    return {
      fields: {
        cardNumber: "v-card-number",
        cardName: "v-card-name",
        cardMonth: "v-card-month",
        cardYear: "v-card-year",
        cardCvv: "v-card-cvv",
      },
      isCardNumberMasked: true,
      mainCardNumber: this.cardNumber,
      cardNumberMaxLength: 19,
      formData: {
        cardName: "",
        cardNumber: "",
        cardMonth: null,
        cardYear: null,
        cardCvv: "",
        cardAccount: "",
        cardAmount: "",
        cardCurrency: "$USD",
        data: null,
        time: null,
        checkbox: false,
      },
      isValid: true,
      required: rules.required,
      amount: rules.amount,
      validCard: rules.validCard,
      onlyLatinLetters: rules.onlyLatinLetters,
      currency: ["$USD", "€EUR"],
      month: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      isCardFlipped: false,
    };
  },
  computed: {
    years() {
      const currentYear = new Date().getFullYear();
      return Array.from({ length: 13 }, (value, index) => currentYear + index);
    },
  },
  mounted() {
    this.maskCardNumber();
  },
  methods: {
    ...mapActions(["writeData"]),
    generateMonthValue(n) {
      return n < 10 ? `0${n}` : n;
    },
    changeName(e) {
      this.formData.cardName = e.target.value;
    },
    changeNumber(e) {
      this.formData.cardNumber = e.target.value;
      this.formData.cardNumber = this.formData.cardNumber
        .replace(/\D/g, "")
        .replace(/(\d{4})/, "$1 ")
        .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
        .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
      this.moveCursorToEnd();
      this.cardNumberMaxLength = 19;
      // eslint-disable-next-line eqeqeq
      if (e.inputType == "deleteContentBackward") {
        let lastChar = this.formData.cardNumber.substring(
          this.formData.cardNumber.length,
          this.formData.cardNumber.length - 1
        );
        // eslint-disable-next-line eqeqeq
        if (lastChar == " ") {
          this.formData.cardNumber = this.formData.cardNumber.substring(
            0,
            this.formData.cardNumber.length - 1
          );
        }
      }
    },
    moveCursorToEnd() {
      this.$nextTick(() => {
        const textField = this.$refs.textField;
        const inputElement = textField.$el.querySelector("input");
        inputElement.selectionStart = inputElement.value.length;
        inputElement.selectionEnd = inputElement.value.length;
      });
    },
    accountNumber(e) {
      this.formData.cardAccount = e.target.value;
      this.formData.cardAccount = this.formData.cardAccount.replace(/\D/g, "");
    },
    cardAmount(e) {
      this.formData.cardAmount = e.target.value;
      this.formData.cardAmount = this.formData.cardAmount.replace(/\D/g, "");
    },
    changeCvv(e) {
      this.formData.cardCvv = e.target.value;
      this.formData.cardCvv = this.formData.cardCvv.replace(/\D/g, "");
    },
    blurCardNumber() {
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      }
    },
    maskCardNumber() {
      this.formData.cardNumberNotMask = this.formData.cardNumber;
      this.mainCardNumber = this.formData.cardNumber;
      let arr = this.formData.cardNumber.split("");
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== "") {
          arr[index] = "*";
        }
      });
      this.formData.cardNumber = arr.join("");
    },
    unMaskCardNumber() {
      this.formData.cardNumber = this.mainCardNumber;
    },
    focusCardNumber() {
      this.unMaskCardNumber();
    },
    toggleMask() {
      if (this.formData.cardNumber) {
        this.isCardNumberMasked = !this.isCardNumberMasked;
        if (this.isCardNumberMasked) {
          this.maskCardNumber();
        } else {
          this.unMaskCardNumber();
        }
      }
    },
    submit() {
      this.$refs.form.validate();
      if (this.isValid) {
        event.preventDefault();
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1;
        const day = currentDate.getDate();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        this.formData.date = `${year}/${month}/${day}`;
        this.formData.time = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        this.writeData(this.formData);
        this.$emit("send");
      }
    },
  },
};
</script>

<style scoped lang="scss">
a {
  font-weight: 700;
  color: #000;
}
::v-deep .v-field--variant-outlined.v-field--focused .v-field__outline {
  --v-field-border-width: 1px;
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}
::v-deep .v-text-field input {
  color: #000;
  font-size: 18px;
}
::v-deep .v-field__outline {
  transition: all 0.3s ease-in-out;
  border: 1px solid #ced6e0;
  border-radius: 10px;
}
.select ::v-deep .v-field {
  height: 51px;
}
:v-deep .v-select__selection-text {
  font-weight: 600;
}
::v-deep .v-field--variant-outlined {
  .v-field__outline__end {
    display: none;
  }
  .v-field__outline__start {
    display: none;
  }
}
::v-deep .v-field--variant-outlined .v-field__outline__notch {
  &::before,
  &::after {
    border-width: 0;
  }
}
::v-deep .v-field--variant-outlined .v-label.v-field-label--floating {
  margin: -10px 15px;
}
.amount {
  ::v-deep .v-field__outline {
    border-radius: 10px 0 0 10px;
  }
}
.amount-select {
  ::v-deep .v-field__outline {
    border-radius: 0 10px 10px 0;
  }
}
.button {
  border-radius: 0 16px 0 16px;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.4;
  display: block;
  margin: 0 auto;
  width: 100%;
}
.checkbox-text {
  font-weight: 300;
  font-size: 12px;
  line-height: 1.4;
  color: #1a3b5d;
}
::v-deep .v-selection-control__wrapper {
  position: absolute;
  top: -2px;
}
.image-wrapper {
  flex-direction: column;
}
.first {
  width: 170px;
  max-width: 170px;
  margin-bottom: 20px;
}
.second {
  width: 87px;
  max-width: 87px;
}
@media screen and (max-width: 560px) {
  .date {
    flex-direction: column;
  }
}
@media screen and (min-width: 744px) {
  .image-wrapper {
    flex-direction: row;
  }
  .first {
    margin-bottom: 0;
  }
}
</style>
